export const config = {
    // apiPath: 'https://us-central1-api-ceva.cloudfunctions.net',
    // apipath: 'http://localhost:5000/portail-api-dev/us-central1',
    // apiPath: 'http://localhost:5000/api-ceva/us-central1',
    // PROD
    apipath: 'https://us-central1-portail-api.cloudfunctions.net',
    swinePath: 'https://us-central1-portail-api.cloudfunctions.net',
    googleClientID: '412869529780-6ci2ldjrt5s6m3i1onuo4pbfekus37ge.apps.googleusercontent.com',
    redirectURL: 'https://docs.ceva.io',

    // DEV
    // apipath: 'https://us-central1-portail-api-dev.cloudfunctions.net',
    // swinePath: 'https://us-central1-portail-api-dev.cloudfunctions.net',
    // googleClientID: '61887506068-psnopjvm22vbv4cijtosa4nb1vun56qt.apps.googleusercontent.com',
    // redirectURL: 'https://portail-api-dev.firebaseapp.com',

    // apipath: 'http://localhost:5001/portail-api/us-central1',
    // swinePath: 'https://us-central1-portail-api-dev.cloudfunctions.net'
};
