import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentsModule } from './pages/components/components.module';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomeModule' },
  { path: 'documentation', loadChildren: './pages/documentation/documentation.module#DocumentationModule' },
  { path: 'connexion', loadChildren: './pages/connexion/connexion.module#ConnexionModule' },
  { path: 'detail/:id', loadChildren: './pages/detail/detail.module#DetailModule' },
  { path: 'user', loadChildren: './pages/user/user.module#UserModule' },
  { path: 'user/:id', loadChildren: './pages/user-form/user-form.module#UserFormModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    ComponentsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
