import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  public log: boolean;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  public goTo(data) {
    const target = '/' + data;
    this.router.navigate([target]);
  }

  public logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('uId');
    localStorage.clear();
    this.router.navigate(['/connexion']);
  }

}
