import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCommonModule, MatSidenavModule, MatListModule, MatExpansionModule, MatIconModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatCommonModule,
        MatSidenavModule,
        MatListModule,
        HttpClientModule,
        MatExpansionModule,
        MatIconModule,
    ],
    exports: [
        HeaderComponent,
        MenuComponent,
    ],
    declarations: [HeaderComponent, MenuComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class ComponentsModule { }
