import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Api } from '../../../services/models/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input()
  public sources: Api | any[];

  @Output()
  public document = new EventEmitter();

  constructor(
    private router: Router
  ) {

  }

  ngOnInit() {  }

  public getDocument(data, i, type, apiId) {
      // const doc = { url: data, swagger: null };
    // if (this.sources[i] && this.sources[i].swagger && this.sources[i].swagger.length === 1) {
    //   doc.swagger = this.sources[i].swagger[0];
    // }
    // if (this.sources[i] && this.sources[i].swagger && this.sources[i].swagger.length > i) {
    //   doc.swagger = this.sources[i].swagger[i];
    // }

    this.document.emit({ data, type, apiId });
  }

  public goEdit(source) {
    this.router.navigate(['/detail', source.id]);
  }

  public goTo(data) {
    const target = '/' + data;
    this.router.navigate([target]);
  }

  public getParseName(url: string) {
    if (url.indexOf('%2F') > -1) {
      url = url.split('%2F')[1];
      if (url.indexOf('?') > -1) {
        url = url.split('?')[0];
      }
    }
    return url;
  }

}
