import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ceva-api';

  constructor(private router: Router, private authService: MsalService) {}

  ngOnInit() {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['/connexion']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  login() {
    this.authService.loginRedirect();
  }
}
