import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule, MatDialogModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { DocumentationModule} from './pages/documentation/documentation.module';
import { ComponentsModule } from './pages/components/components.module';
// import { rapidoc } from '../libs/rapidoc-min';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material';

import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './config';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: '8ff3c2d9-20a5-4e23-8e3d-ae9a9c0acd26', // This is your client ID
        authority: 'https://login.microsoftonline.com/' + '6bd869ea-23ff-419c-8873-c4b11ebc3076', // This is your tenant ID
        redirectUri: config.redirectURL// This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
      }
    }), null, null),
    // AngularFireModule.initializeApp({
    //   apiKey: 'AIzaSyDqySg0yvG9NYoL8E2SP1JxSYkRmv6D49M',
    //   authDomain: 'https://portail-api.firebaseapp.com/',
    //   projectId: 'portail-api',
    // }),
    // AngularFireAuthModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSidenavModule,
    ComponentsModule,
    MatProgressSpinnerModule,
    // DocumentationModule,
    // rapidoc,
    MatCardModule,
    MatNativeDateModule,
  ],
  providers: [],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
